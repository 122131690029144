<template>
  <div ref="page_box" :class=" !weixin && 'page'">
    <div ref="top_box" class="header_box" v-if="!weixin">
      <div style="position: relative;">
        <img src="../../assets/back_arrow.png" alt="" class="arrow" @click="goback">
        {{ part_name }}
      </div>
    </div>
    <div class="main">
      <template v-for="(item,key) in analysisList">
        <div v-show="index===key" :key="key+'jiexi'" class="question-list">
          <div class="question-list-title">
            <span v-show="item.type===1 || item.type==='1'" class="btn_select">
              单选
            </span>
            <span v-show="item.type===2|| item.type==='2'" class="btn_select">
              多选
            </span>
            <span class="question-title" v-html="item.name"></span>
            <span class="question-title2">({{ item.years === 0||item.years === '0' ? '模拟题':item.years+'年真题' }})</span>
          </div>
          <div class="potion_item ">
            <template v-for="(ele,kkk) in item.solution">
              <div :key="kkk" class="item">
                <van-icon v-if="ele.is_true=== '1'||ele.is_true === 1" name="checked" color="#79C94B" size="20" />
                <van-icon v-if="ele.is_true==='0' || ele.is_true === 0" name="circle" size="20" color="#c8c9cc" />
                <span class="xuanxiang">{{ ele.mark }}.{{ ele.name }}</span>
              </div>
            </template>
          </div>
          <div class="jieti">
            <div>正确答案是 <span style="marginLeft:8px">{{ item.answer }}</span></div>
            <div v-if="item.is_true==='0'&&item.user_check===''" class="duicuo">本题未作答 <span style="fontSize:12PX">回答错误</span></div>
            <div v-if="item.is_true==='0'&&item.user_check !==''" class="duicuo">您的答案是{{item.user_check}} <span style="fontSize:12PX">回答错误</span></div>
            <div v-if="item.is_true==='1'|| item.is_true===1" class="duicuo">您的答案是{{item.user_check}} <span style="fontSize:12PX;background-color:rgb(121,201,75)">回答正确</span></div>
          </div>
          <div class="jiexi">
            <div class="jiexi_title">试题解析</div>
            <div class="jiexi_content">
              【解析】<p v-html="item.text_parsing"></p>
            </div>
          </div>
          <div class="btn_box ">
            <span v-show="index>0" class="btn_tab btn_item btn_pre" @click="onPre()">
              <van-icon color="#ee2e2e" name="arrow-left" size="20" />
              上一题
            </span>
            <span v-show="index<=0" class="btn_tab btn_item btn_pre">
            </span>
            <span class="btn_item" style=" text-align: center">
              <span style="color:#333">{{ index+1 }}</span>/{{ len }}
            </span>
            <span v-show="index<len-1" class="btn_tab btn_item btn_next" @click="onNext()">
              下一题
              <van-icon color="#ee2e2e" name="arrow" size="20" />
            </span>
            <span v-show="index>=len-1" class="btn_tab btn_item btn_next"></span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      color: '#ee2e2e',
      radio: '1',
      result: [],
      part_name: '',
      index: 48,
      analysisList: [],
      len: 0,
      weixin:this.weixin,
      daan:""
    }
  },
  created() {
    this.part_name = this.$route.query.part_name,
    this.daan = this.$route.query.daan
    this.index = Number(this.$route.query.index)
    this.analysisList = sessionStorage.getItem('testResult') ? JSON.parse(sessionStorage.getItem('testResult')) : []
    console.log(this.analysisList)
    this.len = this.analysisList.length
  },
  mounted() {
    this.setiosTop()
  },
  methods: {
    setiosTop() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if(this.weixin){
        return false
      }
      if (isiOS) {
        if (u.indexOf(' sms_1 ') !== -1) {
          this.$refs.top_box.style.paddingTop = 40 + 'px'
          this.$refs.page_box.style.paddingTop = 84 + 'px'
        } else {
          this.$refs.top_box.style.paddingTop = 20 + 'px'
          this.$refs.page_box.style.paddingTop = 64 + 'px'
        }
        window.webkit.messageHandlers.vue_Fullscreen.postMessage('1')
      }
    },
    onPre() {
      this.index -= 1
    },
    onNext() {
      this.index += 1
    },
    goback() {
      this.$router.back(-1)
    }
  }

}
</script>

<style lang="scss" scoped>
.page{
    padding-top: 88px;
}
.main{
    overflow-y: scroll;
    padding: 30px 0;
    padding-bottom: 120px;
    margin-bottom: 100px;
    font-size: 12PX;
    .question-list {
      .btn_select{
        display: inline-block;
        padding: 5px 8px;
        color: #fff;
        background: #ee2e2e;
        border-radius: 5px;
      }
    }
    .question-list-title {
        font-size: 25px;
        padding: 0 30px;
    }
    /deep/.question-title{
        font-size: 16PX;
        font-weight: bold;
        font-stretch: normal;
        line-height: 48px;
        letter-spacing: 0px;
        color: #333333;
        margin-left: 10px;
        display: inline;
        word-wrap: break-word;
        word-break: normal;
        p{
          display: inline !important;
          line-height: 30PX;
        }
    }
    .question-title2{
        font-size: 16PX;
        font-weight: normal;
        letter-spacing: 0px;
        color: #999999;
    }
}
.potion_item{
    padding: 40px 30px 0;
    .item{
        padding: 30px 0;
        border-bottom: 1px solid #eeee;
        font-size: 14PX;
        display: flex;
        align-items: center;
        .xuanxiang{
            margin-left: 10px;
        }
    }
}
.jieti{
    background: #fff;
    border-top: 20px solid #eee;
    border-bottom: 20px solid #eee;
    padding: 20px 30px;
    font-size: 14PX;
    .duicuo{
        margin-top: 20px;
        span{
            padding: 5px 10px;
            display: inline-block;
            width: 100px;
            height: 36px;
            font-size: 20px;
            background: #ee2e2e;
            color: #fff;
            border-radius: 8px;
            margin-left: 8px;
        }
    }
}
.jiexi{
    padding: 30px 30px 0;
    .jiexi_title{
        margin-left: 20px;
        position: relative;
        font-size: 16PX;
        color: #999;
    }
    .jiexi_title::before{
        content: '';
        position: absolute;
        left: -20px;
        top: 8px;
        height: 30px;
        width: 4px;
        background: #ee2e2e;
    }
    .jiexi_content{
        margin-top: 20px;
        color: #333;
        font-size: 14PX;
        line-height: 1.5em;
    }
}
.btn_box{
    font-size: 16PX;
    position: fixed;
    bottom: 0px;
    color: #ee2e2e;
    background: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    line-height: 20PX;
    padding: 20px 30px;
    border-top: 1px solid #eee;
    .btn_item{
        display: inline-block;
        // float: left;
        // width: 33%;
        flex: 1;
    }
    .btn_tab{
        display: flex;
        align-items: center;
    }
    .btn_pre{
        border-right: 2px solid #eee;
        text-align: left;
    }
    .btn_next{
        border-left: 2px solid #eee;
        justify-content: flex-end;
    }
}
</style>
